.category-card__image-container {
  position: relative;
  width: 100%;
  height: 80%;
  max-height: 190px;
  padding-top: 90%;
  border-radius: 0.5rem;

  &:hover > img {
    transform: scale(1.03);
  }
}

.category-card__image {
  position: absolute;
  height: 70%;
  width: 70%;
  top: 55%;
  left: 50%;
  margin-top: -35%; /* Half the height */
  margin-left: -35%; /* Half the width */
  object-fit: contain;
  transition: transform 300ms ease-in-out;
}

.category-card__content {
  width: 100%;
  overflow: hidden;
}

.category-card__name {
  font-size: 1.125rem;
  font-weight: $weight--sb;
  line-height: 1.25;
  margin: 1rem 0;
  // Line clamp:
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow : hidden;

  @include breakpoint-up(desktop-sm) {
    font-size: 1.25rem;
  }
}