// Form errors
.form-error {
  margin-top: 14px;
}

.form-error__msg {
  font-size: $font--sm;
  font-weight: $weight--sb;
  color: $color-state--error;
  margin-left: 0.5rem;
}
