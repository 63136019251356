/* ---- COLORS ---- */
// To do: warning, danger, success palettes from Figma

// Neutral
$color-white: #FEFEFE;
$color-grey--100: #F9F9FB;
$color-grey--200: #F1F2F4;
$color-grey--300: #E9ECEE;
$color-grey--400: #DFE3E6;
$color-grey--500: #CED5DA;
$color-grey--600: #ACB5BC;
$color-grey--700: #848F96;
$color-grey--800: #485056;
$color-grey--900: #343A40;
$color-black: #18191F;

// Primary
$color-primary: #F21700;
$color-primary--superlight: #FBF2EC;
$color-primary--light: #FFE6D8;
$color-primary--semilight: #FF9F95;
$color-primary--semidark: #BF1200;
$color-primary--dark: #6A0A00;
$color-primary--superdark: #400600;

// States
$color-state--error: #EA4B7E;
$color-state--success: #00B073;

// Additional colors
$color-peach--light: #fee5da;
$color-canary--light: #fff5ca;
$color-gold: #ffbf47;

 // Font sizes
 $font--xxs: 0.625rem; // 10px
 $font--xs: 0.75rem; // 12px
 $font--sm: 0.875rem; // 14px
 $font--md: 1rem; // 16px
 $font--lg: 1.125rem; // 18px
 $font--xl: 1.25rem; // 20px

 // Font weights
 $weight--md: 500;
 $weight--sb: 600;
 $weight--bold: 700;
 $weight--xb: 800;
 
 // Effects
 $shadow--subtle: 0 2px 10px rgba(32, 33, 37, 0.06);

:export {
  font-xs: $font--xs;
  font-sm: $font--sm;
}