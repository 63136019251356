.ingredient-text {
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
  display: flex !important;
  flex-wrap: nowrap;
  max-width: 25rem;
}

.ingredient-text span {
  font-weight: $weight--md;
}

.ingredient-name, .ingredient-amount {
  margin-left: 5px;
  width: 65%;
}

.ingredient-amount-unit {
  justify-self: flex-end;
}

.ingredient-custom {
  width: 100%;
  margin-left: 5px;
}