// Breakpoints

// 600px, 1024px, 1440px, 1920px
$breakpoints-up: ("tablet": "37.5rem", "desktop-sm": "64rem", "desktop-md": "80rem", "desktop-lg": "90rem", "desktop-xl": "120rem");

// 599px, 1023px, 1439px
$breakpoints-down: ("mobile": "37.4375rem", "tablet": "63.9375rem", "desktop-md": "89.9375rem");

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}