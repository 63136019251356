.filter-container {
  width: 90vw;
  margin: 0 auto;
  padding-top: 20px;

  @include breakpoint-up(tablet) {
    max-width: 600px;
  }

  & > button {
    margin: 4px 3px;
  }
}