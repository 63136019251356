.feature-card-wrapper {
  padding: 20px 0;

  @include breakpoint-up(tablet) {
    flex-direction: row-reverse;
    padding: 40px 0;
  }

  @include breakpoint-up(desktop-lg) {
    padding: 60px 0;
  }
}

.feature-card--is-middle {
  @include breakpoint-up(tablet) {
    flex-direction: initial;
  }
}

.feature-card-container {
  max-width: 720px;

  @include breakpoint-up(tablet) {
    width: 50%;
  }
}

.feature-card-text-container {
  @include breakpoint-up(tablet) {
    padding: 0 20px;
    max-width: 600px;
  }

  @include breakpoint-up(desktop-sm) {
    padding: 0 40px;
  }
}

.feature-card-text-container * {
  margin: 10px 0;
}

.feature-card-cta-container {
  padding-bottom: 20px;
  color: $color-primary;
}

.feature-card-cta-container__link {
  margin-right: 10px;
}