.collapsible-recipe__wrapper {
  width: 90vw;
  max-width: 360px;
  height: auto;
  margin-bottom: 20px;
  transition: 1000ms ease;
  &:hover .collapsible-recipe__title {
    @media(hover: hover) and (pointer: fine) {
      color: $color-primary;
    }
  }
}

.collapsible-recipe__header {
  flex-wrap: nowrap;
  cursor: pointer;
}

.collapsible-recipe__image-container {
  width: 80px;
  height: 80px;
  flex: 0 0 auto;

  @include breakpoint-up(tablet) {
    width: 80px;
    height: 80px;
  }
}

.collapsible-recipe__image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 300ms;
}

.collapsible-recipe__title {
  font-size: $font--sm;
  font-weight: $weight--bold;
  margin: 0 20px;
  // Line clamp:
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.collapsible-recipe__body {
  transition: 0.4s;
  padding-bottom: 10px;
  border-bottom: 0.5px solid $color-grey--300;
}

.collapsible-recipe__hidden-row {
  margin: 1rem 0;
}

.mealcounter_button-container {
  width: 90px;

  // @include breakpoint-up(desktop-sm) {
  //   width: 70px;
  // }
}

// Chevron animation
#chev {
  width: 24px;
  height: 24px;

  @include breakpoint-up(tablet) {
    width: 16px;
    height: 16px;
  }
}

.chevron__lines {
   transition: transform 0.4s;
   transform: translateY(13px);
}

.chevron__line {
  stroke: #111;
  transform-origin: 50px 50px;
  stroke-width: 10;
  stroke-linecap: round;
  transition: transform 0.4s, stroke 0.4s;
  transform: rotate(40deg);
}

.chevron--down .chevron__line-right {
  transform: rotate(-40deg); 
}
.chevron .chevron__line-right, .chevron--up .chevron__line-left {
 transform: rotate(-40deg);
}

.chevron.chevron--up .chevron__line-right {
  transform: rotate(40deg);
}

.chevron--up .chevron__lines {
 transform: translateY(-13px);
}