.toggleable-btn {
  margin-top: 20px;
}

.show-button {
  width: 100%;
  background: $color-primary;
  color: #FFF;
  border-radius: 20px;
}

.hide-button {
  width: 100%;
  background: $color-black;
  color: #FFF;
  border-radius: 20px;
}