.recipe-view-hero_container {
  position: relative;
  top: -8rem;
  left: 0;
  width: 100vw;
  height: 60vh;
  z-index: -1;

  @include breakpoint-up(desktop-sm) {
    height: 80vh;
  }

  /* iPad 1,2, Mini */
  @media only screen
  and (width: 768px)
  and (height: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
      height: 50vh;
  }
}

.recipe-view-hero_image {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.recipe-view-hero_overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33,37,41,0.6);
}

.recipe-view-hero_banner {
  position: relative;
  top: 50%;
  width: 90vw;
  max-width: 71.25rem;
  margin: 0 auto;

  @include breakpoint-up(tablet) {
    width: 80vw;
  }

  @include breakpoint-up(desktop-lg) {
    top: 40%;
  }
}

.recipe-view-hero_banner h1 {
  font-size: 1.5rem;
  color: #fff;

  // 375px and up
  @media screen and (min-width: 22.5rem) {
      font-size: 1.75rem;
  }

  @include breakpoint-up(tablet) {
    font-size: 2.25rem;
  }

  @include breakpoint-up(desktop-sm) {
    font-size: 2.5rem;
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 4rem;
  }

  @include breakpoint-up(desktop-xl) {
    font-size: 5rem;
  }
}

.recipe-view-hero_banner p {
  font-size: $font--md;
  font-weight: $weight--sb;
  color: #fff;
  margin-top: -0.625rem;

  @include breakpoint-up(tablet) {
    margin-top: -1rem;
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 1.25rem;
    margin-top: -1.5rem;
  }

  @include breakpoint-up(desktop-xl) {
    font-size: 1.5rem;
  }
}