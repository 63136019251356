.rv-content {
  position: relative;
  top: -10rem;
  width: 100vw;
  border-radius: 1.875rem 1.875rem 0 0;
  background-color: $color-grey--100;
  padding: 1.25rem 5vw 5rem;
  // margin-bottom: 4.75rem;
  z-index: 90;

  @include breakpoint-up(tablet) {
    border-radius: 0;
  }
}

.rv-bg-hack-container {
  position: relative;
  top: -10rem;
}

.rv-bg-hack {
  position: absolute;
  top: 0;
  background-color: $color-grey--100;
  height: 10rem;
  width: 100vw;
}

.rv-info {
  margin: 0 auto;
  max-width: 71.25rem;
  box-sizing: border-box;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;

  @include breakpoint-up(tablet) {
      width: 90vw;
      position: relative;
      top: -4rem;
      padding: 1.25rem;
      margin-bottom: -1rem;
      z-index: 100;
      background-color: $color-white;
      border-radius: 1.5rem;
      border: 0.5px solid rgba(33, 37, 41, 0.12);
      box-shadow: $shadow--subtle;
      justify-content: space-between;
  }

  @include breakpoint-up(desktop-lg) {
    padding: 2rem;
  }
}

.rv-content-wrapper {
  margin: 0 auto;
  max-width: 71.25rem;
  background-color: $color-grey--100;
  border-radius: 1.5rem;

  @include breakpoint-up(tablet) {
    padding: 40px;
    background-color: $color-white;
    box-shadow: $shadow--subtle;
  }

  @include breakpoint-up(desktop-sm) {
    padding: 60px;
  }
}

.toggle-view {
  margin: 0 auto;
  max-width: 71.25rem;
}

.rv-toggle, .rv-toggle--active {
  font-size: 1.25rem;
  font-weight: $weight--xb;
  border: none;
  background-color: transparent;
  color: $color-grey--900;
  margin-right: 0.75rem;
  padding: 0;

  @include breakpoint-up(desktop-lg) {
    font-size: 1.5rem;
  }
}

.rv-toggle--active {
  border-bottom: $color-primary 2px solid;
  color: $color-primary;
}

.rv-content-container {
  @include breakpoint-up(desktop-sm) {
    display: flex;
    flex-flow: row-reverse;
  }
}

.rv-ingredients {
  margin-top: 20px;
  padding: 20px;
  max-width: 560px;
  border-radius: 1.5rem;
  background-color: $color-white;
  box-shadow: $shadow--subtle;

  @include breakpoint-up(tablet) {
    padding: 30px 40px;
  }

  @include breakpoint-up(desktop-sm) {
    max-width: 440px;
    width: 100%;
  }

  @include breakpoint-up(desktop-md) {
    max-width: 560px; 
  }
}

.rv-optional-ingredients {
  padding-top: 20px;
}

.fixed-bottom-container {
  width: 100vw;
  position: fixed;
  bottom: 56px;
  left: 0;
  padding: 1rem 0;
  margin-top: 2.5rem;
  z-index: 100;

  @include breakpoint-up(tablet) {
    position: static;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
  }
}

.fixed-bottom-container__content {
  margin: 0 auto;
  width: 90%;
  justify-content: flex-end;

  @include breakpoint-up(tablet) {
    width: 100%;
  }
}

.fixed-bottom-container__text {
  font-size: $font--sm;
  font-weight: $weight--sb;
}

