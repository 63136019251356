.footer {
  background-color: transparent;
  margin-top: 8px;
  margin-bottom: 4px;

  @include breakpoint-down(mobile) {
    margin-bottom: 72px;
  }
}

.footer__content {
  width: 90vw;
  max-width: 1280px;
  margin: 0 auto;

  @include breakpoint-up(desktop-xl) {
    max-width: 1600px;
  }
}

.copyright {
  font-size: $font--xs;
  font-weight: $weight--sb;
  text-align: center;
  margin-right: 4px;
}