.btn--secondary {
  background-color: $color-white;
  color: $color-primary;
  border: 2px solid $color-primary;

  &:active {
    transform: translateY(2px)
  }

  &:hover {
    background-color: $color-grey--100;
  }

  // @include breakpoint-up(desktop-sm) {
  //   padding: 0.4rem 1.25rem;
  // }
}