.features-section {
  width: 90vw;
  max-width: 1140px;
  margin: 0 auto;
  padding: 40px 0;

  @include breakpoint-up(desktop-xl) {
    max-width: 1440px;
  }
}

.features-header {
  @include breakpoint-up(tablet) {
    max-width: 600px;
    align-items: center;
  }
}

.features-header__text {
  margin-top: 0;

  @include breakpoint-up(tablet) {
    text-align: center;
  }
}