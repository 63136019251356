.recipe-addition-bg {
  background-color: #F9F5F0;
  width: 100vw;
  height: 100%;
}

.recipe-addition-content {
  width: 90vw;
  max-width: 71.25rem;
  margin: 0 auto;
  padding: 120px 0;
  position: relative;
}

.recipe-addition-title {
  margin-top: 0;
  margin-bottom: 40px;
}

// Side nav
.side-nav {
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.side-nav__heading {
  font-size: 1.25rem;
  color: #20068C;
  margin-top: 20px;
}

.side-nav__list {
  list-style: none;
}
.side-nav__item {
  font-weight: $weight--sb;
  margin-bottom: 10px;
}

.side-nav__link {
  text-decoration: none;
  color: #20068C !important;
  border-bottom: #EA215A 0.125rem solid !important;

  &:visited {
    color: none !important;
   }
 
   &:hover {
     text-decoration: none !important;
   }
 
   &:active {
     padding-top: 1px;
   }
}

.recipe-addition-form-wrapper {
  max-width: 480px;
  margin: 0 auto;
}

#recipe-addition-form__heading {
  margin-top: 40px;
}

.recipe-addition-form__subheading {
  margin-top: 0;
  font-weight: $weight--sb;
}

// Image upload
.file-upload-btn {
  color: $color-black;
  font-weight: $weight--sb;
  background-color: transparent;
  padding: 60px 40px !important;
  margin-top: 20px;
  border: 2px dashed $color-black;
  border-radius: 20px;
  box-shadow: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    color: $color-primary;
    border: 2px dashed $color-primary;
  }
}

// Hide default upload
#upload-photo {
  opacity: 0;
  position: absolute;
}

#upload-photo__icon {
  margin-right: 10px;
}

#ingredient-container {
  position: relative;
  width: 100%;
  max-width: 480px !important;
  display: flex;
  flex-wrap: nowrap;
}

.recipe-addition-form__ingredient {
  margin: 0 10px 0 0;
  padding: 20px 0;
  font-size: $font--sm;
}

// Instructions
#instructions-heading {
  margin-top: 40px;
}

#instructions-container {
  margin: 40px 0;
  flex-direction: column;
  flex-wrap: wrap;
}

#step {
  font-size: $font--sm;
  font-weight: $weight--bold;
}

#ingredient-select {
  font-weight: $weight--md;
  margin: 10px 0;
}

#ingredient-select span {
  font-size: $font--sm;
}

#submit-recipe {
  margin-top: 40px;
}