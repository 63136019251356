.icon__container {
  padding-right: 1.25rem;
}

.icon__text, .icon__text--link {
  margin-left: 10px;
  font-size: $font--sm;
  font-weight: $weight--sb;
}

.icon__text--link {
  &:hover {
    text-decoration: underline;
  }
}