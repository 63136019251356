.categories-section {
  width: 90vw;
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 0;

  @include breakpoint-up(desktop-sm) {
    padding: 80px 0;
  }

  @include breakpoint-up(desktop-xl) {
    max-width: 1600px;
  }
}

.category-listing-wrapper {
  padding-bottom: 40px;

  @include breakpoint-up(desktop-sm) {
    padding-bottom: 80px;
  }
}

.categories-header {
  margin: 0 auto;
  padding-bottom: 20px;

  @include breakpoint-up(tablet) {
    max-width: 600px;
  }

  @include breakpoint-up(desktop-sm) {
    padding-bottom: 30px;
  }
}

.categories-header__text {
  @include breakpoint-up(tablet) {
    text-align: center;
  }
}

.category-listing-container {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  column-gap: 1.25rem;
  row-gap: 2.5rem;

  @media screen and (min-width: 475px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  @include breakpoint-up(tablet) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}