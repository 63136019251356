.hv-hero-section {
	position: relative;
	height: 95vh;
	min-height: 550px;
	max-height: 640px;
	overflow: hidden;
	background: $color-black;

	@include breakpoint-up(tablet) {
		max-height: 800px;
	}

	@include breakpoint-up(desktop-sm) {
		height: 100vh;
		max-height: 1024px;
	}
}

.hv-hero-info-container {
	width: 90vw;
	margin: 0 auto;
	padding-top: 60px;

	@include breakpoint-up(tablet) {
		padding-top: 80px;
		max-width: 540px;
	}
}

.hv-hero-info-container a {
	z-index: 10;
}

.hv-hero__heading {
	font-size: 1.75rem;
	color: #fff;
	margin-bottom: 20px;

	@media screen and (min-width: 22.5rem) { // 360px and larger mobile screens
		font-size: 2rem;
	}

	@include breakpoint-up(desktop-sm) {
		font-size: 3rem;
		font-weight: 900;
		text-align: center;
	}

	@include breakpoint-up(desktop-md) {
		font-size: 3.5rem;
	}

	@include breakpoint-up(desktop-lg) {
		font-size: 4.125rem;
	}

	@include breakpoint-up(desktop-xl) {
		font-size: 4.5rem;
	}
}

.hv-hero__subheading {
	font-size: $font--md;
	line-height: 150%;
	color: #fff;
	margin-bottom: 20px;
	margin-top: 0;

	@media screen and (min-width: 375px) {
		font-size: $font--lg;
	}

	@include breakpoint-up(desktop-lg) {
		font-size: 1.25rem;
		margin-top: 20px;
		margin-bottom: 40px;
	}
}

.hv-hero-image-container {
	position: absolute;
	left: 50%;
	bottom: -30%;
	transform: translateX(-50%);
	width: 200vw;
	min-width: 860px;
	max-width: 1600px;

	@media screen and (min-width: 360px) {
		bottom: -15%;
	}

	@include breakpoint-up(desktop-sm) {
		bottom: -40%;
	}

	@include breakpoint-up(desktop-lg) {
		bottom: -50%;
	}

	@media screen and (min-width: 1680px) {
		bottom: -30%;
	}

	@media screen and (min-aspect-ratio: 20/10) {
		bottom: -80%;
	}

	@media screen and (aspect-ratio: 18/10) { // 1440x800
		bottom: -50%;
	}

	@media screen and (max-width: 1100px) and (min-aspect-ratio: 14/10) {
		bottom: -80%;
	}

	@media screen and (min-width: 1680px) {
		bottom: -30%;
	}
}