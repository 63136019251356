.mobile-nav {
  width: 100vw;
  height: auto;
  position: fixed;
  bottom: 0;
  background: $color-white;
  padding: 8px 0 2px 0;
  z-index: 1000000;
  box-shadow: 10px 10px 48px rgba(226, 221, 215, 0.5);
}

.mobile-nav__items {
  margin: 0 auto;
  width: 90%;
  max-width: 320px;
}

.mobile-nav__item {
  min-width: 68.5px;
}

.mobile-nav__cart {
  position: relative;
}

.mobile-nav__cart-items {
  position: absolute;
  right: 4px;
  top: -4px;
}

.mobile-nav__link > * {
  margin: 2.5px 0;
  color: $color-grey--900;
}

.mobile-nav__icon {
  width: 1.25rem;
  height: 1.25rem;
}

.selected > * {
  color: $color-primary;
}