.sv-h3 {
  margin-top: 0;
  
  @include breakpoint-up(desktop-sm) {
    font-size: 1.25rem;
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 1.5rem;
  }
}