.loading {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 2px solid #000;
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate 1s infinite linear;

  @include breakpoint-down(mobile) {
		margin-bottom: 100px;
	}
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}