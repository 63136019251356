h1 {
  font-size: 2rem; // 32px
  font-weight: $weight--xb;
  line-height: 1.25;
  color: $color-black;

  @include breakpoint-up(desktop-sm) {
    font-size: 2.5rem; // 40px
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 4.125rem; // 66px
  }
}

h2 {
  font-size: 1.75rem; // 28px
  font-weight: $weight--xb;
  line-height: 1.25;
  color: $color-black;

  @include breakpoint-up(tablet) {
    font-size: 2rem; // 32px
  }

  @include breakpoint-up(desktop-sm) {
    font-size: 2.25rem; // 36px
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 3rem; // 48px
  }
}

h3 {
  font-size: 1.5rem; // 24px
  font-weight: $weight--xb;
  line-height: 1.25;
  color: $color-black;

  @include breakpoint-up(desktop-sm) {
    font-size: 2rem; // 32px
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 2.25rem; // 36px
  }
}

h4 {
  font-size: 1.25rem; // 20px
  font-weight: $weight--xb;
  line-height: 1.25;
  color: $color-black;

  @include breakpoint-up(desktop-sm) {
    font-size: 1.5rem; // 24px
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 2rem; // 32px
  }
}

h5 {
  font-size: 1rem; // 16px
  font-weight: $weight--xb;
  line-height: 1.25;
  color: $color-black;
}

p {
  font-size: 1rem; // 16px
  font-weight: $weight--md;
  line-height: 1.5;
  color: $color-black;

  @include breakpoint-up(desktop-lg) {
    font-size: 1.125rem; // 18px
  }
}

// Body text classes
.text--xxs {
  font-size: $font--xxs;
}

.text--xs {
  font-size: $font--xs;
}

.text--sm {
  font-size: $font--sm;
}

.text--lg {
  font-size: $font--md;

  @include breakpoint-up(desktop-sm) {
    font-size: $font--xl;
  }
}

.text--xl {
  font-size: $font--lg;

  @include breakpoint-up(tablet) {
    font-size: $font--xl;
  }

  @include breakpoint-up(desktop-sm) {
    font-size: 1.375rem;
  }
}

// Font weight classes
.text--sb {
  font-weight: $weight--sb;
}

.text--capitalized {
  text-transform: uppercase;
}

// More helper classes
.text--center {
  text-align: center;
}

.text-clipping {
  padding: 0 0.5rem;
  vertical-align: middle;
  background-color: #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  border-radius: 0.5rem;
}

.text-clipping--subtle {
  background: linear-gradient(90deg, $color-peach--light 0%, $color-canary--light 100%);
}

label {
  font-weight: $weight--md;
}
