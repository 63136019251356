// Global styles
@import './scss/variables.module';
@import './scss/mixins.scss';
@import './scss/globals.scss';
@import './scss/buttons.scss';
@import './scss/typography';
// Navigation
@import './components/common/navigation/navigation';
@import './components/common/navigation/links/link-home-view/link-home';
@import './components/common/navigation/links/link-discovery-view/link-return-to-discovery';
@import './components/common/navigation/links/link-shopping-view/link-shopping-view';
@import './components/common/navigation/mobile-nav/mobile-nav';
// Footer
@import './components/common/footer/footer';
// Home view
@import './components/home/hero/hv-hero';
@import './components/home/categories/hv-categories';
@import './components/home/features/hv-features';
@import './components/home/feedback/hv-feedback';
// Discovery view
@import './components/recipe-discovery/discovery-view';
@import './components/recipe-discovery/recipes/dv-recipes';
@import './components/recipe-discovery/recipe-filter/dv-recipe-filter';
// Recipe view
@import './components/recipe-view/recipe-view';
@import './components/recipe-view/hero/rv-hero';
@import './components/recipe-view/content/ingredients/meal-counter/rv-meal-counter';
@import './components/recipe-view/content/instructions/step/rv-step';
// Shopping view
@import './components/shopping-view/shopping-view';
@import './components/shopping-view/placeholder/sv-placeholder';
@import './components/shopping-view/recipes/sv-recipes';
@import './components/shopping-view/recipes/recipe/sv-recipe';
@import './components/shopping-view/ingredients/sv-ingredients';
@import './components/shopping-view/hero/sv-hero';
// Recipe addition
@import './components/recipe-addition/recipe-addition-view';
@import './components/recipe-addition/categories/rav-categories';
// Other components
@import './components/common/recipe-card/recipe-card';
@import './components/common/category-card/category-card';
@import './components/common/feature-card/feature-card';
@import './components/common/icon-with-text/icon-with-text';
@import './components/common/toggleable/toggleable';
@import './components/common/ingredient/ingredient';
@import './components/common/custom-checkbox/custom-checkbox';
@import './components/common/error-message/error-message';
@import './components/common/inputs/custom-input';
@import './components/common/buttons/button-primary/button-primary';
@import './components/common/buttons/button-secondary/button-secondary';
@import './components/common/buttons/button-tertiary/button-tertiary';
@import './components/common/loading/loading';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}