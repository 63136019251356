button {
  font-family: inherit;
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

// General button class
.btn {
  display: inline-block;
  padding: 10px 20px;
  max-width: 240px;
  text-align: center;
  color: #fff;
  font-size: $font--sm;
  font-weight: $weight--bold;
  border: none;
  border-radius: 24px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;
}

// Size variants
.btn--xs {
  padding: 8px 12px;
  font-size: 13px;

  @include breakpoint-up(desktop-sm) {
    font-size: $font--sm;
    font-weight: $weight--sb;
    padding: 5px 10px;
  }
}

.btn--sm {
  padding: 10px 20px;
  font-size: $font--xs;
}

.btn--md {
  font-size: $font--sm;
}

.btn--lg {
  font-size: $font--lg;
}

// Counter buttons
.btn--counter {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

// Image upload button
.btn--image {
  background-color: transparent;
  color: $color-black;
  padding: 40px;
  border: 2px dashed $color-primary;
}