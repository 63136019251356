.btn--primary {
  background-color: $color-primary;
  color: #fff !important;
  border: 2px solid $color-primary;

  &:active {
    transform: translateY(2px)
  }

  &:hover {
    box-shadow: 0 0 0 1px $color-primary;
  }
}