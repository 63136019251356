.feedback-section {
  background-color: $color-black;
  padding: 40px 0;

  @include breakpoint-up(desktop-sm) {
    padding: 80px 0;
  }

  @include breakpoint-up(desktop-xl) {
    padding: 120px 0;
  }
}

.feedback-container {
  width: 90vw;
  max-width: 1140px;
  margin: 0 auto;

  @include breakpoint-down(tablet) {
    justify-content: flex-start;
    flex-direction: column;
  }

  @include breakpoint-up(desktop-xl) {
    max-width: 1440px;
  }
}

.feedback-text-container {
  @include breakpoint-up(desktop-sm) {
    width: 50%;
    max-width: 500px;
  }  
}

.feedback-text-container > p {
  max-width: 400px;
}

.feedback-tally {
  width: 100%;
  min-height: 240px;

  @include breakpoint-up(tablet) {
    max-width: 400px;
    min-height: 300px;
  }

  @include breakpoint-up(desktop-sm) {
    max-width: 320px;
  }
  
}

.feedback-tally__iframe {

  min-height: 200px;

  @include breakpoint-up(desktop-sm) {
    padding-top: 40px;
  }
}
