html {
  font-size: 100%;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box; 
  box-sizing: border-box;
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit; 
  box-sizing: inherit;
  // Remove tap highlight (apply tap styles for interactive elements individually)
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

// Remove outline for non-keyboard :focus
*:focus:not(.focus-visible) {
  outline: none;
}

// Customize focus-visible 
.focus-visible:not(input, textarea) {
  border-radius: 2px;
  box-shadow: 0 0 0 4px $color-gold;
  outline: none;
}

.focus-visible {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: $color-white;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Custom scrollbar
@include breakpoint-up(tablet) {
  body::-webkit-scrollbar {
    width: 1rem;
    background-color: $color-black;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 1px 0 1px hsla(0,0%,100%,.25);
    background-color: $color-black;
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: $color-grey--900;
    box-shadow: inset 3px 3px 3px hsla(0,0%,100%,.25);
    border-radius: 10px;
  }
}

// Links
a, a:hover, a:visited {
  text-decoration: none;
  color: $color-black;
}

a:not(.btn, .link--secondary, .page-link, .logo, .side-nav__item, .icon__text, .icon__text--link, .mobile-nav__link) {
  color: $color-primary;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid transparent;
  transition: all ease-in-out 0.3s;

  &:visited {
    color: #611E6A;
  }
  
  &:hover {
    color: $color-primary !important;
    border-color: $color-primary;
  }

  &:active {
    top: 1px;
  }
}

.link--secondary {
  color: $color-black;
  text-decoration: underline;
}

// Images
img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

// Forms and inputs
input {
  // transition: 0.2s ease;

  &:focus {
    border: 2px solid $color-primary;
  }
}

// Text inputs
input[type=text], input[type=number], textarea {
  width: 100%;
  max-width: 480px;
  border: 2px solid $color-black;
  padding: 16px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: $font--sm;
  background-color: $color-white;

  &::placeholder {
    font-size: $font--sm;
    color: #555;
  }

  &:focus {
    outline: .25rem solid $color-gold;
    outline-offset: 0;    
  }
}

// Remove autofill background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
    box-shadow: 0 0 0px 1000px $color-white inset;
}

// Disable textarea resizing
textarea {
  resize: none;
  font-family: 'Inter' !important;
  line-height: 1.4;
}

// Remove number input arrows
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.form-hint {
  font-size: $font--sm;
  margin-bottom: 0;
}

select {
  width: 100%;
}

.input-error {
  border: 2px solid $color-state--error !important;
}

// Flexbox helper classes
.flex {
  display: flex;
  flex-flow: row wrap;

  &-vertical {
    flex-direction: column;
  }

  &-no-wrap {
    flex-wrap: nowrap;
  }

  &-jc-sb {
    justify-content: space-between;
  }

  &-jc-c {
    justify-content: center;
  }

  &-ai-c {
    align-items: center;
  }
}

// Color helper classes
.is-white {
  color: #fff !important;
}

.currentcolor {
  color: currentColor !important;
}

// Visibility
.hide-for-mobile {
  @include breakpoint-down(mobile) {
    display: none;
  }
}

.hide-for-tablet-up {
  @include breakpoint-up(tablet) {
    display: none;
  }
}

.hide-for-tablet-down {
  @include breakpoint-down(tablet) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(desktop-sm) {
    display: none;
  }
}