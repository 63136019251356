.nav__link--shopping {
  margin-right: 10px;
  margin-top: 20px;
}

.nav__shopping-bag-wrapper {
  margin-top: -12px;
}

.nav__shopping-bag-container {
  max-height: 100%;
  height: 2rem;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.nav__shopping-bag-items {
  align-self: flex-start;
  background: $color-black;
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  margin-top: 0.25rem;
}

.nav__shopping-bag-items--added {
  background: $color-primary;
}

.nav__shopping-bag-items p {
  color: #fff !important;
  text-align: center;
  font-size: $font--xxs;
  font-weight: $weight--sb;
  line-height: 1.45;
  margin-block-start: 0;
  margin-block-end: 0;
}