.instruction-step {
  box-sizing: border-box;
  max-width: 560px;
  background-color: $color-white;
  box-shadow: $shadow--subtle;
  border-radius: 1.25rem;
  padding: 20px;
  margin-top: 20px;

  @include breakpoint-up(tablet) {
    padding: 30px 40px;
  }

  @include breakpoint-up(desktop-sm) {
    max-width: 440px;
    width: 100%;
  }

  @include breakpoint-up(desktop-md) {
    max-width: 560px; 
  }
}

.instruction-step__name {
  color: $color-primary;
}

.instruction-step__heading {
  font-size: $font--sm;
  font-weight: $weight--bold;

  @include breakpoint-up(desktop-sm) {
    font-size: $font--md;
  }
}

.instruction-step__info {
  font-size: $font--sm;
  
  @include breakpoint-up(desktop-sm) {
    font-size: $font--md;
  }
}

ul {
  list-style-position: inside;
  padding: 0;
  margin: 2px 0; 
}