.sv-recipe-container {
  @include breakpoint-down(tablet) {
    padding: 20px 5vw;
    background-color: $color-white;
  }
  
  @include breakpoint-up(desktop-sm) {
    padding-top: 2.5rem;
  }

  @include breakpoint-up(desktop-md) {
    padding-top: 60px;
  }
}

.btn-remove-recipe {
  color: $color-primary;
  background-color: $color-primary--light;
  border-radius: 15px;
  padding: 5px 10px;
  border-style: hidden;
  margin: 0 0.625rem 1rem 0;
  font-weight: bold;
}

.x-mark {
  margin-right: 5px;
  font-size: 15px;
}