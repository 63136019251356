.recipe-card__image-container {
  position: relative;
  width: 100%;
  height: 80%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 1.25rem;

  &:hover > div, &:focus > div {
    transform: scale(1.03);
  }
}

.recipe-card__image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transition: transform 300ms ease-in-out;
  background-color: $color-grey--200; // fallback color
  background-position: center center;
  background-size: cover;
}

.recipe-card__content {
  width: 100%;
  overflow: hidden;
}

.recipe-card__name {
  font-size: $font--md;
  font-weight: $weight--bold;
  line-height: 1.25;
  margin: 0.625rem 0;
  // Line clamp:
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow : hidden;
}

.recipe-card__chip {
  background-color: $color-primary--light;
  width: 3.375rem;
  border-radius: 15px;
}

.recipe-card__chip-text {
  color: $color-primary;
  font-size: $font--xs;
  font-weight: $weight--sb;
  padding: 3px 0;
  text-align: center;
}