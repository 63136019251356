.sv-bg {
  width: 100vw;
  background-color: $color-grey--100;
}

.sv-content-wrapper {
  width: 100vw;
  max-width: 71.25rem;
  margin: 0 auto;
  padding: 64px 0;

  @include breakpoint-up(desktop-sm) {
    padding: 5rem 0;
    width: 90vw;
  }
}

.sv-content-container {
  @include breakpoint-up(desktop-sm) {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5rem;
  }
}

.shopping-placeholder-text {
  max-width: 640px;
  margin-bottom: 40px;
}