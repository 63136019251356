.sv-ingredient-container {
  width: 100vw;
  padding: 0 5vw 0;
  background-color: $color-white;
  box-shadow: $shadow--subtle;

  @include breakpoint-up(desktop-sm) {
    width: 55%;
    padding: 40px;
    border-radius: 1.5rem;
  }

  @include breakpoint-up(desktop-md) {
    width: 60%;
    padding: 60px;
  }

  @include breakpoint-up(desktop-md) {
    width: 60%;
  }
}

.sv-ingredient-container__header {
  @include breakpoint-up(desktop-sm) {
    padding-bottom: 1.25rem;
  }
}

.sv-h1 {
  @include breakpoint-up(desktop-sm) {
    font-size: 1.75rem;
    margin: 0;
  }

  @include breakpoint-up(desktop-lg) {
    font-size: 2rem;
  }
}

.sv-ingredient-group {
  margin: 40px 0;
}

// Add ingredient component

.sv-add-ingredient {
  padding: 20px 0 40px 0;
}

.add-ingredient-form {
  position: relative;
  padding-top: 20px;
}

.add-ingredient-form__label {
  font-weight: $weight--sb;
  color: $color-black;
  padding-bottom: 20px;
}

#ingredient-name {
  max-width: 360px;
}

.add-ingredient-form__btn-container {
  max-width: 180px;
}

.add-ingredient-form__button {
  position: absolute;
  left: 80px;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}