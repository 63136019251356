.btn--tercery {
  color: $color-primary;
  background-color: $color-primary--light;
  transition: background-color 0.2s ease;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background-color: #FFD8C2;
  }
}