.nav {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 4rem;
  z-index: 100;
  transition: 0.2s ease;
  
  @include breakpoint-up(desktop-sm) {
    height: 4.375rem;
  }
}

.nav--light-bg {
  background-color: rgba(254,254,254,0.9);
}

.nav--transparent {
  color: #fff !important; // cascades white to currentColor elements
}

nav.scroll {

  @include breakpoint-up(tablet) {
    position: fixed;
    box-shadow: $shadow--subtle;
    background-color: rgba(254,254,254,0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: $color-black !important;
    transition: 0.2s ease;
  }
}

.nav__content {
  width: 90vw;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  flex-wrap: nowrap;

  @include breakpoint-up(desktop-xl) {
    max-width: 1600px;
  }
}

.nav__content--right {
  margin: 0 -10px;
}

.nav__content--right * {
  color: currentColor !important;
}

.nav__link {
  margin: 0 10px;
  padding: 10px;
  font-size: $font--xs;
  font-weight: $weight--sb;
  color: currentColor;

  &:hover {
    background: hsla(0,0%,100%,0.2);
    border-radius: 20px;
  }

  @include breakpoint-up(desktop-sm) {
    font-size: $font--sm;
  }
}

.nav__link > * {
 margin-left: 10px;
}