.recipe-listing {
  width: 90vw;
  max-width: 1280px;
  margin: 0 auto;
  padding: 80px 0;

  @include breakpoint-up(desktop-xl) {
    max-width: 1600px;
  }
}

.recipe-listing_container {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(8.125rem, 1fr));
  column-gap: 1.25rem;
  row-gap: 2.5rem;

  @include breakpoint-up(tablet) {
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }

  @include breakpoint-up(desktop-sm) {
    grid-template-columns: repeat(auto-fill, minmax(13.125rem, 1fr));
  }
}