.meal-counter-wrapper {
  max-width: 71.25rem;
  margin: 0 auto;
}

.meal-counter_container {
  box-sizing: border-box;
  background-color: $color-white;
  max-width: 560px;
  border-radius: 1.25rem;
  padding: 30px 1.5rem;
  margin: 1.5rem 0;
  box-shadow: $shadow--subtle;

  @include breakpoint-up(desktop-sm) {
    padding: 30px 80px;
  }
}

.meal-counter_title {
  text-align: center;
  font-size: 1.125rem;
  font-weight: $weight--sb;
  margin-top: 0;
}

.meal-counter {
  margin: 0 auto;
  width: 150px;
}

.current-count {
  font-size: 1.25rem;
  font-weight: $weight--sb;
}