label > input[type="checkbox"] {
  display: none;
}

label > input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.5rem !important;
  height: 1.5rem;
  margin-right: 0.3125rem;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: $color-grey--700;
}

label > input[type="checkbox"]:checked + *::before {
  content: "✓";
  color: $color-white;
  text-align: center;
  background: $color-black;
  border-color: $color-black;
}

label > input[type="checkbox"] + * {
  display: inline-block;
  /* padding: 0.5rem 1rem; */
}

/* strike through text */
label > input[type="checkbox"]:checked ~ div > * {
  text-decoration: line-through;
}
